import React, { useState , useEffect } from 'react';
import { Box, Typography, Button, Divider , IconButton ,CircularProgress } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import Papa from 'papaparse'; // For CSV parsing
import * as XLSX from 'xlsx'; // For Excel parsing
import Snackbar from '@mui/material/Snackbar';
import axios from 'axios'; // For sending parsed data to backend
import { ORG_FETCH, ORG_UPLOAD, ORG_UPLOAD_REPLACE } from '../../api/orgAuth';
import { FiUpload } from "react-icons/fi";
import { colors } from '@material-ui/core';
import { useSyncExternalStore } from 'react';
import { USER } from '../../api/vsc';
import { useParams } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const HalfCircleProgress = ({ percentage, color }) => {
    const radius = 50; // Radius of the circle
    const circumference = 2 * Math.PI * radius; // Circumference of the circle
    const strokeDashoffset = circumference - (percentage / 100) * circumference; // Stroke offset for progress
  
    return (
      <svg width="100%" viewBox="0 0 120 60">
        {/* Background circle with gradient */}
        <circle
          cx="60"
          cy="60"
          r={radius}
          fill="transparent"
          stroke="#c1ff72"
          strokeWidth="7"
          strokeDasharray={circumference}
          transform="rotate(90 60 60)"  // Rotated by 90 degrees to start from left
        />
        {/* Foreground circle for progress */}
        <circle
          cx="60"
          cy="60"
          r={radius}
          fill="transparent"
          stroke={color}
          strokeWidth="7"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          transform="rotate(90 60 60)"  // Rotated by 90 degrees to start from left
          style={{ transition: 'stroke-dashoffset 0.6s ease' }}
        />
      </svg>
    );
  };
  const formatTime = (time) => {
    // Convert time to string if it's not already a string
    time = String(time || "");
    console.log(time)
    // If time is still empty or doesn't include a colon, return a placeholder
    if (!time || !time.includes(":")) {
      return "Invalid Time"; // Handle invalid or missing time
    }
    console.log(time)
    const [hour, minute] = time.split(":").map(Number);
  
    // Validate parsed hour and minute
    if (isNaN(hour) || isNaN(minute) || hour < 0 || hour > 23 || minute < 0 || minute > 59) {
      return "Invalid Time"; // Handle invalid time format
    }
  
    // Convert 24-hour to 12-hour format
    const isPM = hour >= 12;
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12; // Convert 0 or 12 to 12 for 12-hour format
    const suffix = isPM ? "PM" : "AM";
  
    return `${formattedHour}:${minute < 10 ? `0${minute}` : minute} ${suffix}`;
  };
  const formatTime2 = (fraction) => {
  const totalMinutes = fraction * 24 * 60; // Convert fraction to total minutes
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};

function convertAndFormatExcelDate(excelDate, format = "dd-mm-yyyy") {
  // Excel base date is January 1, 1900, but Excel incorrectly considers 1900 as a leap year.
  const excelBaseDate = new Date(1900, 0, 1); 
  
  // Fix for the Excel leap year bug: Subtract one day for dates after 1900.
  // Excel incorrectly assumes that 1900 was a leap year, causing the date to be off by one day.
  const jsDate = new Date(excelBaseDate.getTime());
  jsDate.setDate(jsDate.getDate() + excelDate - 2); // Subtract 2 days to compensate for Excel's leap year bug

  // Format the date according to the provided format
  let day = jsDate.getDate();
  let month = jsDate.getMonth() + 1; // Months are 0-based in JavaScript
  let year = jsDate.getFullYear();

  // Ensure two-digit formatting for day and month
  if (day < 10) day = '0' + day; 
  if (month < 10) month = '0' + month; 

  // Return the formatted date string in the desired format
  return format.replace('dd', day).replace('mm', month).replace('yyyy', year);
}


    
  
const OrgDashboard = ({isAuth , setIsAuth , data}) => {
  const [file, setFile] = useState(null);
  const [loading , setLoading] = useState(false);
  const [user , setUser] = useState({});
  const [swotPeople , setSwotPeople] = useState([]);
  const params = useParams();
  const [refresh , setRefresh] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); // Default rows per page
  const [csvDownloadUrl, setCsvDownloadUrl] = useState(''); 
  const [snackbarOpen, setSnackbarOpen] = useState(false); 
  const [uploadProgress, setUploadProgress] = useState(0);
  const navigate = useNavigate();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when rows per page changes
  };
  const handleClick = () => {
    navigate('/allstudents', { state: { swotPeople: swotPeople } });
  };

  useEffect(() => {
    
      axios
      .get(USER, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        setUser(response.data);
      })
      .catch((error) => {
        console.error(error);
      });

      axios
      .get(ORG_FETCH+params.id)
      .then(res => {
        setSwotPeople(res.data.data);
      })
      .catch(err => {
        console.error(err);
      })

      setRefresh(false);
    }, [refresh]);

    const handleFileUpload = (event, from) => {
      const uploadedFile = event.target.files[0];
      if (uploadedFile) {
        setFile(uploadedFile);
    
        // Check the file type
        const fileType = uploadedFile.name.split('.').pop();
        if (fileType === 'csv') {
          // Parse CSV file
          Papa.parse(uploadedFile, {
            header: true,
            complete: (results) => {
              const transformedData = transformDataForSchema(results.data);
              from === '1'
                ? sendDataToBackend(transformedData)
                : sendDataToBackend2(transformedData); // Send parsed and transformed data to backend
            },
          });
        } else if (fileType === 'xlsx' || fileType === 'xls') {
          // Parse Excel file
          const reader = new FileReader();
          reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);
    
            const transformedData = transformDataForSchema(jsonData);
            from === '1'
              ? sendDataToBackend(transformedData)
              : sendDataToBackend2(transformedData); // Send parsed and transformed data to backend
          };
          reader.readAsArrayBuffer(uploadedFile);
        } else {
          alert('Please upload a valid CSV or Excel file.');
        }
      }
    };
    
    // Transform the data as per requirements
    const transformDataForSchema = (data) => {
      return data.map((row, index) => {
      const parsedTime = formatTime2(row["Time"])
      console.log(convertAndFormatExcelDate(row["Date"]) , row["Date"]);
        return {
          name: row["Name"] || "",
          email: row["Email ID"] || row["Email"]   || "",
          whatsappNumber: row["WhatsappNumber"] || "",
          bookingDate: convertAndFormatExcelDate(row["Date"]) || "",
          time: formatTime(parsedTime) || "",
        };
      });
    };
    
 const sendDataToBackend = (parsedData) => {
  setLoading(true)
  axios.post(ORG_UPLOAD + window.localStorage.id, parsedData)
  .then((response) => {
    setRefresh(true);
    setLoading(false);

    // Check if there are unavailable bookings in the response
    if (response.data.unavailableBookings && response.data.unavailableBookings.length > 0) {
      const unavailableBookings = response.data.unavailableBookings;

      // Convert unavailableBookings to Excel/CSV
      const ws = XLSX.utils.json_to_sheet(unavailableBookings); // Convert array of objects to worksheet
      const wb = XLSX.utils.book_new(); // Create a new workbook
      XLSX.utils.book_append_sheet(wb, ws, 'Unavailable Bookings'); // Append sheet to the workbook

      // Generate CSV file
      const csvFile = XLSX.write(wb, { bookType: 'csv', type: 'array' });
      const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob); // Create a URL for the file

      setCsvDownloadUrl(url); // Store the URL for the download
      setSnackbarOpen(true); // Open the snackbar
    }
  })
  .catch((error) => {
    setLoading(false);
    console.error('Error uploading data:', error);
    // Optionally, handle the error with another snackbar or log
  });
 }
 const sendDataToBackend2 = (parsedData) => {
  setLoading(true)
  let progress = 0;
  const interval = setInterval(() => {
    progress += 10;
    setUploadProgress(progress);
    if (progress >= 100) {
      clearInterval(interval);
      setLoading(false);
    }
  }, 300);
  axios.post(ORG_UPLOAD_REPLACE + window.localStorage.id, parsedData)
  .then((response) => {
    setRefresh(true);
    setLoading(false);

    // Check if there are unavailable bookings in the response
    if (response.data.unavailableBookings && response.data.unavailableBookings.length > 0) {
      const unavailableBookings = response.data.unavailableBookings;

      // Convert unavailableBookings to Excel/CSV
      const ws = XLSX.utils.json_to_sheet(unavailableBookings); // Convert array of objects to worksheet
      const wb = XLSX.utils.book_new(); // Create a new workbook
      XLSX.utils.book_append_sheet(wb, ws, 'Unavailable Bookings'); // Append sheet to the workbook

      // Generate CSV file
      const csvFile = XLSX.write(wb, { bookType: 'csv', type: 'array' });
      const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob); // Create a URL for the file

      setCsvDownloadUrl(url); // Store the URL for the download
      setSnackbarOpen(true); // Open the snackbar
    }
  })
  .catch((error) => {
    setLoading(false);
    console.error('Error uploading data:', error);
    // Optionally, handle the error with another snackbar or log
  });
 }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false); // Close the Snackbar
  };

  const triggerFileInput = () => {
    document.getElementById('file-upload').click();
  };
  const triggerFileInput2 = () => {
    document.getElementById('file-upload1').click();
  };

  const handleDownload = () => {
    // Data for the Excel sheet
    const data = [
      { "Name": "Astha", "WhatsappNumber": "9999999999", "Email ID": "astha@gmail.com", "RollNo": "764546", "Date": "15-01-2025", "Time": "18:15" }
    ];

    // Convert JSON to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write the workbook and trigger a download
    XLSX.writeFile(workbook, "sample_sheet.xlsx");
  };

  return (
    <Box sx={{ padding: '20px', fontFamily: 'Arial', marginTop: '120px' }}>
      <Typography variant="h4" sx={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '20px' }}>
        College Industry Collab
      </Typography>

      {/* Top Section with Snapshot and Students Appearing for Interview */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        {/* Snapshot Box */}
        <Box sx={{ width: '50%' }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            <span
              style={{
                backgroundColor: '#F5C663',
                padding: '5px 10px',
                borderRadius: '10px',
              }}
            >
              Snapshot
            </span>
          </Typography>
          <Box
            sx={{
              border: '1px solid #000',
              padding: '20px',
              width: '90%',
              borderRadius: '10px',
              height: '70%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', alignItems: 'center' , width: '100%'}}>
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                  {user?.totalAppearingStudents || 0 }
                </Typography>
                <Typography variant="h6">Enrolled</Typography>
              </Box>
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                  {swotPeople.filter(swot => swot.bookingDate).length}
                </Typography>
                <Typography variant="h6">Bookings</Typography>
              </Box>
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                  {swotPeople.filter(swot => swot.ratingOverall).length}
                </Typography>
                <Typography variant="h6">Appeared</Typography>
              </Box>
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                  {swotPeople.filter(swot => swot.ratingOverall*10 > 80).length}
                </Typography>
                <Typography variant="h6">Above 80% Score</Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ width: '50%' }}>
      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
        <span
          style={{
            backgroundColor: '#F5C663',
            padding: '5px 10px',
            borderRadius: '10px',
          }}
        >
          Students Appearing for Interview
        </span>
      </Typography>
      <Box
        sx={{
          border: '1px solid #000',
          padding: '20px',
          width: '90%',
          borderRadius: '10px',
        }}
      >
      <Box  sx={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', height: '100px'}}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
          <input
            type="file"
            accept=".csv, .xlsx, .xls"
            style={{ display: 'none' }}
            id="file-upload"
            onChange={(e)=> handleFileUpload(e,'1')}
          />
          <label htmlFor="file-upload">
            <IconButton onClick={triggerFileInput} sx={{ padding: '10px 20px' }}>
              {loading ? <CircularProgress/>:<FiUpload style={{ fontSize: '80px' }} />}
            </IconButton>
            <Typography>Add Students</Typography>
          </label>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
          <input
            type="file"
            accept=".csv, .xlsx, .xls"
            style={{ display: 'none' }}
            id="file-upload1"
            onChange={(e)=> handleFileUpload(e,'2')}
          />
          <label htmlFor="file-upload1">
            <IconButton onClick={triggerFileInput2} sx={{ padding: '10px 20px' }}>
              {loading ? <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress 
              variant="determinate" 
              value={uploadProgress} 
              size={80} 
              thickness={5} 
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="caption" component="div" color="textSecondary">
                {`${uploadProgress}%`}
              </Typography>
            </Box>
          </Box>:<FiUpload style={{ fontSize: '80px' }} />}
            </IconButton>
            <Typography>Replace All Students</Typography>
          </label>
        </Box>

        <Box>
          <Button  variant="contained" sx={{background: '#6a58c6'}} onClick={handleDownload}>
            Download Sample Sheet
          </Button>
        </Box>
      </Box>
        
        
        {/* Add instructions about the file format here */}
        <Typography variant="body2" sx={{ marginTop: '10px', textAlign: 'center' }}>
          Please upload a CSV or Excel File Of List Of Students. The file should contain the following columns: 
          <strong> Name, WhatsappNumber, Email ID, Roll No, Date(Format: DD-MM-YYYY) , Time: (Format: MM:HH)</strong>. ( Follow This ! Column Names Are Case Sensitive )
        </Typography>
      </Box>
    </Box>
    </Box>

      {/* SWOT Analysis and Consolidated Report Section */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        {/* SWOT Analysis Box */}
        <Box sx={{ width: '50%' }}>
         <Box
           sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
           }}
         >
         <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            <span
              style={{
                backgroundColor: '#F5C663',
                padding: '5px 10px',
                borderRadius: '10px',
              }}
            >
              SWOT Analysis of People
            </span>
          </Typography>
          <Button
            sx={{
              background: '#6a58c6',
              color: '#fff',
              marginRight: '20px'
            }}
            onClick={handleClick}
          >
            View All
          </Button>
         </Box>
          <Box
      sx={{
        border: '1px solid #000',
        padding: '20px',
        width: '90%',
        borderRadius: '10px',
      }}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Sl.</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Report</TableCell>
              <TableCell>Rating</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {swotPeople
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) // Pagination logic
              .map((person, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                  <TableCell>{person.name}</TableCell>
                  <TableCell>{person.bookingDate || 'Not Booked'}</TableCell>
                  <TableCell>
                    <Button
                      variant="text"
                      size="small"
                      sx={{
                        textTransform: 'none',
                        fontSize: '10px',
                        textDecorationStyle: 'underline',
                        color: '#000',
                      }}
                      disabled={!person.ratingOverall}
                      href={`/studentreport/${person._id}`} // Disable if no date is booked
                    >
                      View Report
                    </Button>
                  </TableCell>
                  <TableCell>{person.ratingOverall ? "⭐".repeat(person.ratingOverall/2) : 'No Rating'}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]} // Options for rows per page
        component="div"
        count={swotPeople.length} // Total number of rows
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>

    </Box>

        {/* Consolidated Report Box */}
        <Box sx={{ width: '50%' }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            <span
              style={{
                backgroundColor: '#F5C663',
                padding: '5px 10px',
                borderRadius: '10px',
              }}
            >
              Consolidated Report
            </span>
          </Typography>
          <Box
            sx={{
              border: '1px solid #000',
              padding: '20px',
              width: '90%',
              borderRadius: '10px',
            }}
          >
            <Box sx={{ marginTop: '10px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ textAlign: 'center', width: '45%' }}>
                  <HalfCircleProgress percentage={40} color="#00bf63" />
                  <Typography sx={{ marginTop: '10px' }}>
                  40%  of students <br/>demonstrated excellent communication skills
                  </Typography>
                </Box>
                <Box sx={{ textAlign: 'center', width: '45%' }}>
                  <HalfCircleProgress percentage={60} color="#00bf63" />
                  <Typography sx={{ marginTop: '10px' }}>
                  60%  of students <br/>
                    exhibited leadership potential
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ marginY: '20px' }} />
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ textAlign: 'center', width: '45%' }}>
                  <HalfCircleProgress percentage={60} color="#00bf63" />
                  <Typography sx={{ marginTop: '10px' }}>
                  60%  of students <br/>
                    need improvement in technical tools
                  </Typography>
                </Box>
                <Box sx={{ textAlign: 'center', width: '45%' }}>
                  <HalfCircleProgress percentage={50} color="#00bf63" />
                  <Typography sx={{ marginTop: '10px' }}>
                    50%  of students <br/>
                    need improvement in critical thinking
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          </Box>
      </Box>

      <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />
      <Snackbar
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
      message="Some bookings were unavailable"
      action={
        <Button
          color="secondary"
          size="small"
          href={csvDownloadUrl} // The CSV download link
          download="unavailable_bookings.csv" // File name
        >
          Download CSV
        </Button>
      }
    />
    </Box>
  );
};

export default OrgDashboard;
