import * as React from 'react';
import { 
  Avatar, 
  Button, 
  Card, 
  LinearProgress, 
  Box, 
  Typography, 
  Grid, 
  Container, 
  useMediaQuery, 
  useTheme 
} from '@mui/material';
import axios from 'axios';
import { ORG_STUDENT_SINGLE } from '../../api/orgAuth';
import { useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import StudentReportPDF from './StudentReportPDF';
import {  pdf } from '@react-pdf/renderer';
const StudentReport = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const params = useParams();
  
  const [student, setStudent] = React.useState({});
  const [average, setAverage] = React.useState({});
  const [labels, setLabels] = React.useState([
    { label: 'Communication', value: 0 },
    { label: 'Knowledge', value: 0 },
    { label: 'Experience', value: 0 },
    { label: 'Training', value: 0 },
    { label: 'Confidence', value: 0 },
    { label: 'Dynamism', value: 0 },
  ]);

  const renderHTMLContent = (htmlContent) => {
    // Sanitize the HTML content to remove malicious scripts
    const cleanHTML = DOMPurify.sanitize(htmlContent);
    // Parse the sanitized HTML and return it as plain text
    return parse(cleanHTML);
  };

  React.useEffect(() => {
    axios
    .get(ORG_STUDENT_SINGLE + params.id)
    .then(res => {
      console.log(res);
      setStudent(res.data.booking);
      setAverage(res.data.averageRatings);
      setLabels([
        { label: 'Communication', value: Number(res.data.booking.communicationRating), average: Number(res.data.averageRatings.communicationRating) },
        { label: 'Knowledge', value: Number(res.data.booking.knowledgeRating), average: Number(res.data.averageRatings.knowledgeRating) },
        { label: 'Experience', value: Number(res.data.booking.experienceRating), average: Number(res.data.averageRatings.experienceRating) },
        { label: 'Training', value: Number(res.data.booking.trainingRating), average: Number(res.data.averageRatings.trainingRating) },
        { label: 'Confidence', value: Number(res.data.booking.confidenceRating), average: Number(res.data.averageRatings.confidenceRating) },
        { label: 'Dynamism', value: Number(res.data.booking.dynamismRating), average: Number(res.data.averageRatings.dynamismRating) },
      ]);
    })
    .catch(err => {
      console.error(err);
    })
  },[])
  const downloadReport = async () => {
    const blob = await pdf(<StudentReportPDF student={student} labels={labels} />).toBlob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${student?.name || 'Student'}_Report.pdf`;
    link.click();
    URL.revokeObjectURL(url);
  };
  
  const renderStudentInfo = () => {
    const infoFields = [
      {label:'Name' , value: student?.name}, 
      {label: 'Roll Number' , value: student?.studentCode}, 
      {label: 'College' , value: student?.orgCode}, 
      {label: 'Date' , value: student?.bookingDate}, 
      {label: 'Time' , value: student?.time}
    ];

    return infoFields.map((field) => (
      <Grid 
        container 
        key={field.label} 
        spacing={1} 
        sx={{ 
          marginBottom: isMobile ? 1 : 0.5,
          flexDirection: isMobile ? 'column' : 'row'
        }}
      >
        <Grid item xs={isMobile ? 12 : 6}>
          <Typography 
            variant="body2" 
            sx={{ 
              fontWeight: '500', 
              fontSize: isMobile ? '12px' : '14px' 
            }}
          >
            {`${field?.label} :  ${field?.value}`}
          </Typography>
        </Grid>
        {/* <Grid item xs={isMobile ? 12 : 3}>
          <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{ fontSize: isMobile ? '12px' : '14px' }}
          >
            {field?.value}
          </Typography>
        </Grid> */}
      </Grid>
    ));
  };

  const renderRatingComponent = (label, value, average, index) => (
    <Box 
      key={label} 
      sx={{ 
        marginBottom: 3,
        padding: isMobile ? 1 : 0
      }}
    >
      <Typography 
        variant="subtitle1" 
        sx={{ 
          fontWeight: '500', 
          marginBottom: 1,
          fontSize: isMobile ? '14px' : '16px'
        }}
      >
        {`${label} :`}
      </Typography>
      <Typography 
        variant="body2" 
        color="text.secondary" 
        sx={{ 
          marginBottom: 1,
          fontSize: isMobile ? '12px' : '14px'
        }}
      >
        Based on how accurately and relevantly you conveyed your {label.toLowerCase()}.
      </Typography>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: isMobile ? 1 : 2 
      }}>
        <Box sx={{ flexGrow: 1 }}>
          <LinearProgress
            variant="determinate"
            value={(value/10)*100}
            sx={{
              width: '100%',
              height: '8px',
              borderRadius: '4px',
              backgroundColor: '#ffffff',
              '& .MuiLinearProgress-bar': {
                background: 'linear-gradient(90deg, #ffc01f, #afffd6)',
                borderRadius: '4px',
              },
            }}
          />
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              marginTop: 0.5, 
              width: '100%',
              flexWrap: isMobile ? 'wrap' : 'nowrap',
              gap: isMobile ? 0.5 : 0
            }}
          >
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
              <Typography
                key={num}
                variant="caption"
                sx={{
                  fontSize: isMobile ? '10px' : '12px',
                  fontWeight: num === average ? '600' : '400',
                  backgroundColor: num === average ? '#7affc9' : '#fff',
                  padding: '2px',
                  borderRadius: '5px',
                  flex: isMobile ? '0 1 calc(10% - 4px)' : 'none',
                  textAlign: 'center'
                }}
              >
                {num}
              </Typography>
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: '#FFB84C',
            borderRadius: '50%',
            height: isMobile ? 36 : 48,
            width: isMobile ? 36 : 48,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: '500',
            fontSize: isMobile ? '12px' : '14px'
          }}
        >
          {value}
        </Box>
      </Box>
    </Box>
  );

  return (
    <Container 
      maxWidth="lg" 
      sx={{ 
        padding: isMobile ? '16px' : '24px', 
        marginTop: isMobile ? '60px' : '120px' 
      }}
    >
      {/* Header Section */}
      <Grid 
        container 
        spacing={2} 
        sx={{ 
          marginBottom: 4,
          flexDirection: isMobile ? 'column-reverse' : 'row'
        }}
      >
        <Grid 
          item 
          xs={12} 
          md={8} 
          sx={{ 
            display: 'flex', 
            flexDirection: isMobile ? 'column' : 'row', 
            alignItems: 'start', 
            gap: 2 
          }}
        >
          <Box 
            component="img" 
            src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png" 
            alt="Profile" 
            sx={{ 
              height: isMobile ? 72 : 96, 
              width: isMobile ? 72 : 96, 
              borderRadius: '10px' 
            }} 
          />
          <Box sx={{ width: '100%' }}>
            {renderStudentInfo()}
          </Box>
        </Grid>
        <Grid 
          item 
          xs={12} 
          md={4} 
          sx={{ 
            textAlign: isMobile ? 'left' : 'right',
            marginBottom: isMobile ? 2 : 0 
          }}
        >
          <Button
            variant="contained"
            sx={{ 
              backgroundColor: '#FFB84C', 
              color: '#fff', 
              marginBottom: 1,
              width: isMobile ? '100%' : 'auto'
            }}
            onClick={downloadReport}
          >
            Download
          </Button>
          <Box sx={{ fontSize: isMobile ? '12px' : '14px' }}>
            <Typography variant="body2">Top 10% : Yes/No</Typography>
            <Typography variant="body2">Rating : {student?.ratingOverall}</Typography>
          </Box>
        </Grid>
      </Grid>

      {/* Main Content Section */}
      <Grid container spacing={isMobile ? 2 : 3}>
        {/* Ratings Section */}
        <Grid 
          item 
          xs={12} 
          md={8} 
          sx={{ 
            paddingRight: isMobile ? 0 : 3,
            paddingBottom: isMobile ? 2 : 0 
          }}
        >
          {labels.map((label, index) => 
            renderRatingComponent(label.label, label.value, label.average, index)
          )}
        </Grid>

        {/* Strengths, Weaknesses, Feedback Section */}
        <Grid item xs={12} md={4}>
      <Grid container spacing={2}>
        {/* Strengths & Weaknesses */}
        <Grid item xs={12} sx={{ display: 'flex', gap: isMobile ? 1 : 3 }}>
          {['Strength', 'Weakness'].map((section) => (
            <Card
              key={section}
              sx={{
                flex: 1,
                padding: isMobile ? 1 : 2,
                backgroundColor: '#7affc9',
              }}
            >
              <Box
                sx={{
                  backgroundColor: '#FFB84C',
                  margin: isMobile ? '-8px' : '-16px',
                  padding: isMobile ? '4px 8px' : '8px 16px',
                  fontWeight: '500',
                  marginBottom: 2,
                }}
              >
                {section}
              </Box>
              <Typography variant="body2" sx={{ fontSize: isMobile ? '12px' : '14px' }}>
                {section === 'Strength'
                  ? renderHTMLContent(student?.strength || '')
                  : renderHTMLContent(student?.weakness || '')}
              </Typography>
            </Card>
          ))}
        </Grid>

        {/* Feedback & Suggestions */}
        {['Feedback', 'Suggestion'].map((section) => (
          <Grid item xs={12} key={section}>
            <Card
              sx={{
                padding: isMobile ? 1 : 2,
                backgroundColor: '#7affc9',
              }}
            >
              <Box
                sx={{
                  backgroundColor: '#FFB84C',
                  margin: isMobile ? '-8px' : '-16px',
                  padding: isMobile ? '4px 8px' : '8px 16px',
                  fontWeight: '500',
                  marginBottom: 2,
                }}
              >
                {section}
              </Box>
              <Box sx={{ minHeight: isMobile ? '100px' : '200px' }}>
                {section === 'Feedback'
                  ? renderHTMLContent(student?.feedback || '')
                  : renderHTMLContent(student?.suggestion || '')}
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Grid>
      </Grid>
    </Container>
  );
};

export default StudentReport;