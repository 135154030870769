import { useState } from 'react';
import {
    Box,
    Tab,
    Tabs,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Link,
    Button
} from '@mui/material';
import { useLocation } from 'react-router-dom';

const TruncatedCell = ({ text }) => {
    const [expanded, setExpanded] = useState(false);
    const handleToggle = () => setExpanded(!expanded);

    if (!text) return 'N/A';

    return (
        <>
            {expanded ? text : text.length > 30 ? <div dangerouslySetInnerHTML={{ __html:`${text.substring(0, 30)}...`}}/> : <div dangerouslySetInnerHTML={{ __html: text}}/>}
            {text.length > 30 && (
                <Button size="small" onClick={handleToggle} sx={{ textTransform: 'none', ml: 1 }}>
                    {expanded ? 'See Less' : 'See More'}
                </Button>
            )}
        </>
    );
};

const AllStudents = () => {
    const location = useLocation();
    const { swotPeople } = location.state || { swotPeople: [] };
    
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (event, newValue) => setSelectedTab(newValue);

    const filteredData = swotPeople.filter(student => {
        const nameMatch = student.name?.toLowerCase().includes(searchTerm.toLowerCase());
        const isBooked = !!student.bookingDate;

        return selectedTab === 0 ? nameMatch && isBooked : nameMatch && !isBooked;
    });

    return (
        <Box sx={{ marginTop: '120px', padding: '0 20px' }}>
            <Typography variant='h4' textAlign={'center'} mb={10}>
                All Students 
            </Typography>
            
            <TextField
                label="Search Students"
                variant="outlined"
                sx={{ marginBottom: '20px', width: '30%' }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />

            <Tabs value={selectedTab} onChange={handleTabChange} aria-label="student status tabs">
                <Tab label="Booked" />
                <Tab label="Unbooked" />
            </Tabs>

            <Typography variant="h5" sx={{ marginTop: '20px', fontWeight: 'bold' }}>
                {selectedTab === 0 ? 'Booked Students List' : 'Unbooked Students List'}
            </Typography>

            <TableContainer sx={{ marginTop: '20px' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Sl.</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>WhatsApp Number</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Strength</TableCell>
                            <TableCell>Weakness</TableCell>
                            <TableCell>Opportunity</TableCell>
                            <TableCell>Threat</TableCell>
                            <TableCell>Feedback</TableCell>
                            <TableCell>Screenshot</TableCell>
                            <TableCell>Resume</TableCell>
                            <TableCell>Rating</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData.length > 0 ? (
                            filteredData.map((student, index) => (
                                <TableRow key={student.studentCode || index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{student.name || 'N/A'}</TableCell>
                                    <TableCell>{student.email || 'N/A'}</TableCell>
                                    <TableCell>{student.whatsappNumber || 'N/A'}</TableCell>
                                    <TableCell>{student.bookingDate || 'N/A'}</TableCell>
                                    <TableCell>{student.status || 'N/A'}</TableCell>
                                    <TableCell><TruncatedCell text={student.strength} /></TableCell>
                                    <TableCell><TruncatedCell text={student.weakness} /></TableCell>
                                    <TableCell><TruncatedCell text={student.opportunity} /></TableCell>
                                    <TableCell><TruncatedCell text={student.threat} /></TableCell>
                                    <TableCell><TruncatedCell text={student.feedback} /></TableCell>
                                    <TableCell>
                                        {student.screenshot ? (
                                            <Link href={student.screenshot} target="_blank" rel="noopener">
                                                View Screenshot
                                            </Link>
                                        ) : 'N/A'}
                                    </TableCell>
                                    <TableCell>
                                        {student.resume ? (
                                            <Link href={student.resume} target="_blank" rel="noopener">
                                                View Resume
                                            </Link>
                                        ) : 'N/A'}
                                    </TableCell>
                                    <TableCell>{student.ratingOverall || 'N/A'}</TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={14} align="center">
                                    No students found.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default AllStudents;
